import { ResponsiveContainer, XAxis, YAxis, AreaChart, Area } from "recharts";
import React from "react";
import { Box } from "@mui/material";

export type ElevationPoint = {
  name: string;
  elevation: number;
  point: [number, number];
  // amt: number;
};

export const ElevationChart = React.memo(
  ({ points }: { points: ElevationPoint[] }) => {
    return !points.length ? null : (
      <Box
        sx={{
          backgroundColor: "rgba(255,255,255,0.5)",
          width: "calc(100%)",
          height: 150,
          bottom: 0,
          position: "absolute",
        }}
      >
        <ResponsiveContainer width="100%">
          <AreaChart
            data={points}
            margin={{
              top: 5,
              right: 15,
              left: -15,
              bottom: -5,
            }}
          >
            {/* <CartesianGrid stroke="" /> */}
            <XAxis dataKey="name" />
            <YAxis dataKey="elevation" />
            {/* <Tooltip /> */}
            {/* <Legend /> */}
            {/* <Line
          type="monotone"
          dataKey="pv"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        /> */}
            <Area
              type="monotone"
              dataKey="elevation"
              stroke="gray"
              strokeWidth={2}
              fill="lightgray"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    );
  }
);
ElevationChart.displayName = "ElevationChart";
