import React, { useCallback, useEffect, useMemo, useState } from "react";
import gpxParser from "gpxparser";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import UndoIcon from "@mui/icons-material/Undo";
import mapboxgl, {
  EventData,
  GeoJSONSource,
  Map,
  MapEventType,
  MapMouseEvent,
} from "mapbox-gl";

import "mapbox-gl/dist/mapbox-gl.css";
import { buildGPX, GarminBuilder } from "gpx-builder";
import { saveAs } from "file-saver";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import debounce from "debounce";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { usePersistedState, usePrevious } from "../utils/utils";
import { lat2tile, lon2tile, tile2lat, tile2long } from "../utils/helpers";
import { DropdownMenu } from "./DropdownMenu";
import { startMobileAd } from "./Adsterra";
import { ElevationChart, ElevationPoint } from "./ElevationChart";
import {
  END,
  END_HOVER,
  ROUTE_LINE,
  ROUTE_LINE_WIDTH,
  ROUTE_POINT,
  ROUTE_POINT_WIDTH,
  START,
  START_HOVER,
  WAYPOINT_OUTLINE,
  WAYPOINT_OUTLINE_WIDTH,
} from "../utils/colours";
import { ContactForm } from "./ContactForm";
import Login from "./Login";
import {
  ArrowDropDown,
  ArrowDropUp,
  ArrowForwardIos,
  ArrowUpward,
  Clear,
  Landscape,
} from "@mui/icons-material";
import { FeatureGroup } from "leaflet";
import FilesDragAndDrop from "./FilesDragAndDrop";
import DragAndDrop from "./DragAndDrop";
import { useNavigate } from "@tanstack/react-router";
// import { MyContext } from "../App";

// eslint-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
mapboxgl.workerClass =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

mapboxgl.accessToken =
  "pk.eyJ1IjoieWdsaWFtIiwiYSI6ImNrbGZyaWoxbzByY2MybnAwczE5ZzNtdWMifQ.IP7WCz-NWi5OpZZESRRsIA";

export const API = process.env.REACT_APP_API;
export const { Point } = GarminBuilder.MODELS;

export enum MouseButton {
  LEFT = 0,
  RIGHT = 2,
}

let layers: { overlayId: string; lng: number; lat: number }[] = [];

export const styles = {
  sidebarElement: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
    justifyContent: "center",
    backgroundColor: "#404040 !important",
    color: "#ffffff",
    padding: 1,
    margin: 1,
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "#6d6d6d",
    },
    pointerEvents: "all",
    textTransform: "initial",
  },
  selectRoot: {
    padding: 0,
  },
};

export enum DistanceUnit {
  MILES = "miles",
  KM = "km",
}

export const fetchImage = debounce(
  async (
    _lng: number,
    _lat: number,
    _zoom: number,
    map: Map,
    heatmapEnabled: boolean
  ) => {
    let zoom = Math.round(_zoom);
    if (zoom > 15) {
      zoom = 15;
    }
    const _x = lon2tile(_lng, zoom);
    const _y = lat2tile(_lat, zoom);
    const tileRange = 2;
    for (let x = _x - tileRange; x < _x + tileRange; x++) {
      for (let y = _y - tileRange; y < _y + tileRange; y++) {
        const sourceId = `tile-${x}-${y}-${zoom}`;
        const overlayId = `overlay-${x}-${y}-${zoom}`;
        const source = map.getSource(sourceId) as mapboxgl.ImageSource;
        if (source) {
          // already exists
          // source.updateImage({
          //   url: objectURL,
          //   coordinates
          // });
          break;
        }
        fetch(`${API}/api?x=${x}&y=${y}&zoom=${zoom}`)
          .then((resp) => resp.blob())
          .then((blob) => {
            if (blob.type !== "image/png") {
              return;
            }
            const objectURL = URL.createObjectURL(blob);

            const lng = tile2long(x, zoom);
            const lat = tile2lat(y, zoom);
            const lengthX = tile2long(x + 1, zoom) - lng;
            const lengthY = tile2lat(y - 1, zoom) - lat;
            const coordinates = [
              [lng, lat],
              [lng + lengthX, lat],
              [lng + lengthX, lat - lengthY],
              [lng, lat - lengthY],
            ];
            map.addSource(sourceId, {
              type: "image",
              url: objectURL,
              coordinates,
            });
            layers.push({ overlayId, lng, lat });
            map.addLayer({
              id: overlayId,
              source: sourceId,
              type: "raster",
              paint: {
                "raster-opacity": 0.25,
              },
            });
            map.setLayoutProperty(
              overlayId,
              "visibility",
              heatmapEnabled ? "visible" : "none"
            );
          })
          .catch((e) => {
            console.log("error fetching zoom", zoom, e);
          });
      }
    }
  },
  1000
);

function MapBoxLayer() {
  const [heatmapEnabled, setHeatmapEnabled] = usePersistedState(
    "heatmapEnabled",
    true
  );
  const isMobile = useMemo(() => window.innerWidth <= 768, []);
  const [menuDropdownOpen, setMenuDropdownOpen] = React.useState(!isMobile);
  const [waypoints, setWaypoints] = React.useState<[number, number][]>([]);
  const [randomRouteLength, setRandomRouteLength] = React.useState(3);
  const [route, setRoute] = React.useState<GeoJSON.Position[]>([]);
  const [map, setMap] = React.useState<Map | null>(null);
  const [canvas, setCanvas] = React.useState<HTMLElement>();
  const [lng, setLng] = React.useState(-0.1339);
  const [lat, setLat] = React.useState(51.5273);
  const [zoom, setZoom] = React.useState(14);
  const [distance, setDistance] = React.useState(0);
  const [distanceUnit, setDistanceUnit] = usePersistedState(
    "distanceUnit",
    DistanceUnit.MILES
  );
  const [elevations, setElevations] = React.useState<ElevationPoint[]>([]);
  const [elevation, setElevation] = React.useState(0);
  const [waypointHover, setWaypointHover] = React.useState(false);

  let mapContainer: HTMLElement | string = "mapContainer";

  const removeLayer = useCallback(
    (id: string) => {
      if (map?.getSource(id)) {
        map.removeLayer(id);
        map.removeSource(id);
      }
    },
    [map]
  );

  const undoWaypoint = () => {
    if (!map) return;
    setWaypoints(waypoints.slice(0, waypoints.length - 1));
  };

  const goToMe = async (newMap: mapboxgl.Map) => {
    await navigator.geolocation.getCurrentPosition((position) => {
      newMap.setCenter({
        lon: position.coords.longitude,
        lat: position.coords.latitude,
      });
      newMap.zoomTo(14);
    });
  };

  const navigate = useNavigate({ from: "/" });

  const readFile = useCallback(
    (event: any) => {
      const xml = event.target.result;
      const gpx = new gpxParser();
      gpx.parse(xml);
      navigate({ to: "/edit", state: (s) => ({ ...s, gpx }) });
    },
    [navigate]
  );

  const onUpload = useCallback(
    (fileList: FileList) => {
      const reader = new FileReader();
      reader.addEventListener("load", readFile);
      reader.readAsText(fileList.item(0)!);
    },
    [readFile]
  );

  const generateGPX = () => {
    const points = route.map(
      (waypoint: number[]) => new Point(waypoint[1], waypoint[0])
    );
    const gpxData = new GarminBuilder();
    gpxData.setSegmentPoints(points);
    const csv = buildGPX(gpxData.toObject());
    const blob = new Blob([csv], { type: "text/xml" });
    saveAs(blob, "route.gpx");
  };

  const clearWaypoints = useCallback(() => {
    removeLayer("start");
    removeLayer("end");
    removeLayer("route");
    for (let i = 1; i < waypoints.length - 1; i++) {
      removeLayer(`point${i}`);
    }
    setWaypoints([]);
    setRoute([]);
    setDistance(0);
    setElevation(0);
    setElevations([]);
  }, [removeLayer, waypoints.length]);

  const getPointProperties = async (point: GeoJSON.Position) => {
    if (point.length < 2) {
      return undefined;
    }
    const elevationJson = (await (
      await fetch(
        `http://dev.virtualearth.net/REST/v1/Elevation/List?points=${point[1]},${point[0]}&key=${process.env.REACT_APP_BING_KEY}`
      )
    ).json()) as {
      resourceSets: { resources: { elevations: number[] }[] }[];
    };
    const elevation = elevationJson?.resourceSets
      .find((e) => e !== undefined)
      ?.resources.find((e) => e !== undefined)
      ?.elevations.find((e) => e !== undefined);

    const streetsJson = (await (
      await fetch(
        `https://api.mapbox.com/v4/mapbox.mapbox-streets-v8/tilequery/${point[0]},${point[1]}.json?r&limit=1&radius=25&dedupe&access_token=${mapboxgl.accessToken}`
      )
    ).json()) as GeoJSON.FeatureCollection<GeoJSON.Geometry>;

    const properties = streetsJson.features
      .map((f) => f?.properties)
      .map((e) => e)
      .find((e) => e);

    return { elevation, ...properties, point } as GeoJSON.GeoJsonProperties;
  };

  const getRouteElevation = React.useCallback(
    async (route?: GeoJSON.Position[]) => {
      if (!route) return;
      const elevations = (
        (await (
          await fetch(
            `https://dev.virtualearth.net/REST/v1/Elevation/List?points=${route
              .map((point) => `${point[1]},${point[0]}`)
              .join(",")}&key=${process.env.REACT_APP_BING_KEY}`
          )
        ).json()) as {
          resourceSets: { resources: { elevations: number[] }[] }[];
        }
      ).resourceSets[0].resources[0].elevations;
      // const pointProperties = (
      //   await Promise.all(
      //     route.map(async (point: number[]) => await getPointProperties(point))
      //   )
      // ).filter((e) => e?.elevation !== undefined);
      // const elevations = pointProperties
      //   .map((e) => e?.elevation)
      //   .filter((e) => e !== undefined);
      if (!elevations) return;
      const positiveElevationChange = elevations.reduce(
        ({ prev, total }, e) => ({
          prev: e,
          total: prev ? (e > prev ? total + e - prev : total) : total,
        }),
        {
          prev: null,
          total: 0,
        } as { prev: null | number; total: number }
      ).total;
      const negativeElevationChange = elevations.reduce(
        ({ prev, total }, e) => ({
          prev: e,
          total: prev ? (e < prev ? total + prev - e : total) : total,
        }),
        {
          prev: null,
          total: 0,
        } as { prev: null | number; total: number }
      ).total;
      setElevations(
        elevations.map((e, i) => ({
          name: i + "",
          elevation: e,
          point: route[i] as [number, number],
        }))
      );
      setElevation(positiveElevationChange + negativeElevationChange);
    },
    []
  );

  const addLayer = React.useCallback(
    (
      id: string,
      data:
        | GeoJSON.Feature<GeoJSON.Geometry>
        | GeoJSON.FeatureCollection<GeoJSON.Geometry>,
      radius: number,
      initialColour: string,
      hoverColour: string
    ) => {
      if (!map || !canvas?.style) {
        return;
      }
      map.addLayer({
        id,
        type: "circle",
        source: {
          type: "geojson",
          data,
        },
        paint: {
          "circle-radius": radius,
          "circle-color": initialColour,
          "circle-stroke-width": WAYPOINT_OUTLINE_WIDTH,
          "circle-stroke-color": WAYPOINT_OUTLINE,
        },
      });
      map.on("mouseenter", id, () => {
        setWaypointHover(true);
        map.setPaintProperty(id, "circle-color", hoverColour);
        canvas.style.cursor = "move";
      });
      map.on("mouseleave", id, () => {
        map.setPaintProperty(id, "circle-color", initialColour);
        if (canvas) {
          canvas.style.cursor = "";
        }
        setWaypointHover(false);
      });
    },
    [canvas, map]
  );

  const prevWaypoints = usePrevious(waypoints);
  React.useEffect(() => {
    if (!map || waypoints === prevWaypoints) return;
    if (waypoints.length === 0) {
      if (prevWaypoints.length) {
        clearWaypoints();
      }
      return;
    }
    if (waypoints.length === 1) {
      prevWaypoints.forEach((_, i) => {
        removeLayer(`point${i}`);
      });
      removeLayer("end");
      removeLayer("route");
      return;
    }
    if (!waypoints.length) {
      return;
    }
    (async function () {
      let url = "https://api.mapbox.com/directions/v5/mapbox/walking/";
      waypoints.forEach((r) => {
        url += `${r[0]},${r[1]};`;
      });
      url = `${url.slice(
        0,
        -1
      )}?geometries=geojson&annotations=distance&access_token=${
        mapboxgl.accessToken
      }`;
      prevWaypoints.forEach((_, i) => {
        removeLayer(`point${i}`);
      });
      for (let i = 1; i < waypoints.length - 1; i++) {
        const id = `point${i}`;
        const point = waypoints[i];
        const data = {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Point",
            coordinates: [point[0], point[1]],
          },
        } as GeoJSON.Feature<GeoJSON.Geometry>;
        if (map.getSource(id)) {
          (map.getSource(id) as GeoJSONSource).setData(data);
        } else {
          addLayer(id, data, ROUTE_POINT_WIDTH, ROUTE_POINT, ROUTE_POINT);
        }
      }

      const end = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Point",
              coordinates: waypoints[waypoints.length - 1],
            },
          },
        ],
      } as GeoJSON.FeatureCollection<GeoJSON.Geometry>;

      const response = await fetch(url);
      const json = await response.json();

      const data = json?.routes?.length && json.routes[0];
      const newRoute = data?.geometry.coordinates as GeoJSON.Position[];
      const legs = data?.legs as {
        steps: number[];
        weight: number;
        distance: number;
        summary: string;
        duration: number;
        annotation?: {
          distance?: number[];
        };
      }[];
      // let distance = 0;
      // getRouteElevation(newRoute.map(point => ({...point, distance += point})));
      getRouteElevation(newRoute);
      setDistance(legs.reduce((acc, leg) => acc + leg.distance, 0) || 0);
      setRoute(newRoute);
      const geojson = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: newRoute,
        },
      } as GeoJSON.Feature<GeoJSON.Geometry>;
      if (map.getSource("route")) {
        (map.getSource("route") as GeoJSONSource).setData(geojson);
      } else {
        map.addLayer({
          id: "route",
          type: "line",
          source: {
            type: "geojson",
            data: geojson,
          },
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": ROUTE_LINE,
            "line-width": ROUTE_LINE_WIDTH,
            "line-opacity": 0.75,
          },
        });
      }
      if (map.getLayer("end")) {
        (map.getSource("end") as GeoJSONSource).setData(end);
      } else {
        addLayer("end", end, 10, END, END_HOVER);
      }
    })();
  }, [
    addLayer,
    clearWaypoints,
    getRouteElevation,
    map,
    prevWaypoints,
    removeLayer,
    waypoints,
  ]);

  const getClosestPathPoint = useCallback(
    async (coordinates: {
      lng: number;
      lat: number;
    }): Promise<[number, number]> => {
      const resp = await fetch(
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
          coordinates.lng +
          "," +
          coordinates.lat +
          `.json?access_token=${mapboxgl.accessToken}&types=address`
      ).then((r) => r.json());
      // type of routing dropdown
      // console.log("resp", resp);
      return [coordinates.lng, coordinates.lat];
      return (
        (resp.features[0]?.geometry?.coordinates as [number, number]) ?? [
          coordinates.lng,
          coordinates.lat,
        ]
      );
    },
    []
  );

  function randomGeo(
    center: { latitude: number; longitude: number },
    radius: number
  ) {
    const y0 = center.latitude;
    const x0 = center.longitude;
    const rd = radius / 111300;

    const u = Math.random();
    const v = Math.random();

    const w = rd * Math.sqrt(u);
    const t = 2 * Math.PI * v;
    const x = w * Math.cos(t);
    const y = w * Math.sin(t);

    return {
      latitude: y + y0,
      longitude: x + x0,
    };
  }

  const generateRandomRoute = async () => {
    // 3 miles
    // const diff = 0.01;
    // const radius = 100;

    // 10 miles
    // const diff = 0.035;
    // const radius = 100;

    const diff =
      0.0035 *
      (distanceUnit === DistanceUnit.KM
        ? randomRouteLength * 0.621371
        : randomRouteLength);
    const radius = 100;

    // 5.5 miles
    // const diff = 0.02;
    // const radius = 100;

    // 7.5 miles
    // const diff = 0.03;
    // const radius = 100;

    console.log("lat", lat, lat + diff);
    const a = randomGeo({ latitude: lat + diff, longitude: lng }, radius);
    const b = randomGeo(
      { latitude: lat + diff, longitude: lng + diff },
      radius
    );
    const c = randomGeo({ latitude: lat, longitude: lng + diff }, radius);

    const p1 = await getClosestPathPoint({ lat, lng });
    const p2 = await getClosestPathPoint({ lat: a.latitude, lng: a.longitude });
    const p3 = await getClosestPathPoint({ lat: b.latitude, lng: b.longitude });
    const p4 = await getClosestPathPoint({ lat: c.latitude, lng: c.longitude });

    setWaypoints([p1, p2, p3, p4, p1]);

    // setWaypoints([
    //   [lng, lat],
    //   [b.longitude, b.latitude],
    //   [c.longitude, c.latitude],
    //   [lng, lat],
    // ]);
  };
  // const generateRandomRoute = () => {
  //   const randomPoint = randomGeo({ latitude: lat, longitude: lng }, 1000);
  //   addMapPoint([randomPoint.longitude, randomPoint.latitude]);
  // };

  const toggleHeatmap = useCallback(() => {
    setHeatmapEnabled(!heatmapEnabled);
  }, [heatmapEnabled, setHeatmapEnabled]);

  const onMove = React.useCallback(
    (pointIndex: number) => (e: MapEventType["mousemove"] & EventData) => {
      const coords = e.lngLat;
      if (canvas) {
        canvas.style.cursor = "grabbing";
      }
      const geojson = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Point",
              coordinates: [coords.lng, coords.lat],
            },
          },
        ],
      } as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
      // const distances = waypoints.map(
      //   (waypoint) =>
      //     Math.abs(waypoint[0] - e.lngLat.lng) +
      //     Math.abs(waypoint[1] - e.lngLat.lat)
      // );
      if (pointIndex > -1) {
        (
          map?.getSource(
            pointIndex === 0
              ? "start"
              : pointIndex === waypoints.length - 1
              ? "end"
              : `point${pointIndex}`
          ) as GeoJSONSource
        )?.setData(geojson);
      }
    },
    [canvas, map, waypoints.length]
  );

  const addMapPoint = React.useCallback(
    (coords: [number, number]) => {
      if (!canvas || !map) {
        return;
      }
      canvas.style.cursor = "";
      const end = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Point",
              coordinates: coords,
            },
          },
        ],
      } as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
      if (waypoints.length === 0) {
        if (map.getLayer("start")) {
          (map.getSource("start") as GeoJSONSource).setData(end);
        } else {
          addLayer("start", end, 5, START, START_HOVER);
        }
      } else if (map.getLayer("end")) {
        (map.getSource("end") as GeoJSONSource).setData(end);
      } else {
        addLayer("end", end, 5, END, END_HOVER);
      }
      setWaypoints([...waypoints, coords]);
    },
    [addLayer, canvas, map, waypoints]
  );

  const addClosestMapPoint = useCallback(
    async (coordinates: { lng: number; lat: number }) => {
      const c = await getClosestPathPoint(coordinates);
      addMapPoint([c[0], c[1]]);
    },
    [addMapPoint, getClosestPathPoint]
  );

  const onMapClick = React.useCallback(
    async (e: MapEventType["click"] & EventData) => {
      if (waypointHover) {
        return;
      }
      addClosestMapPoint(e.lngLat);
    },
    [addClosestMapPoint, waypointHover]
  );

  const onMouseUp = React.useCallback(
    (pointIndex: number) => (e: MapEventType["mouseup"] & EventData) => {
      const newWaypoints = [...waypoints];
      const { lat, lng } = e.lngLat;
      newWaypoints[pointIndex] = [lng, lat];
      setWaypoints(newWaypoints);
      if (canvas) {
        canvas.style.cursor = "";
      }
    },
    [canvas, waypoints]
  );

  const fetchTile = useCallback(async () => {
    if (!map) {
      return;
    }
    await fetchImage(lng, lat, zoom, map, heatmapEnabled);
  }, [map, lng, lat, zoom, heatmapEnabled]);

  useEffect(() => {
    fetchTile();
  }, [lng, lat, fetchTile]);

  useEffect(() => {
    const bounds = map?.getBounds();
    if (bounds) {
      const tilesInView = layers.filter(
        ({ overlayId, lng: layerLng, lat: layerLat }) => {
          const isInView =
            layerLng < bounds.getNorthEast().lng &&
            layerLng > bounds.getSouthWest().lng &&
            layerLat < bounds.getNorthEast().lat &&
            layerLat > bounds.getSouthWest().lat;
          if (map?.getLayer(overlayId)) {
            map?.removeLayer(overlayId);
          }
          return isInView;
        }
      );
      layers = tilesInView;
    }
  }, [map, zoom]);

  useEffect(() => {
    if (!map) {
      return;
    }
    layers.forEach(({ overlayId }) => {
      map.setLayoutProperty(
        overlayId,
        "visibility",
        heatmapEnabled ? "visible" : "none"
      );
    });
  }, [heatmapEnabled, map]);

  const onMapMove = useCallback((e: MapMouseEvent) => {
    const newLng = +e.target.getCenter().lng;
    const newLat = +e.target.getCenter().lat;
    const newZoom = Math.round(+e.target.getZoom());
    setLng(newLng);
    setLat(newLat);
    setZoom(newZoom);
  }, []);

  const onMouseDown = React.useCallback(
    (e: MapMouseEvent) => {
      const {
        lngLat: { lat, lng },
        originalEvent: { button },
      } = e;
      const distances = waypoints.map(
        (waypoint) => Math.abs(waypoint[0] - lng) + Math.abs(waypoint[1] - lat)
      );
      const closest = Math.min(...distances);
      const pointIndex = distances.findIndex((d) => d === closest);
      if (pointIndex > -1 && closest < 0.001) {
        e.preventDefault();
        if (button === MouseButton.LEFT) {
          if (canvas) {
            canvas.style.cursor = "grab";
          }
          const onMoveListener = onMove(pointIndex);
          map?.on("mousemove", onMoveListener);
          map?.once("mouseup", (e) => {
            onMouseUp(pointIndex)(e);
            map?.off("mousemove", onMoveListener);
          });
        } else if (button === MouseButton.RIGHT) {
          setWaypoints(waypoints.filter((_, i) => i !== pointIndex));
        }
      }
    },
    [canvas, map, onMouseUp, onMove, waypoints]
  );

  React.useEffect(() => {
    if (!map) return;
    map.on("click", onMapClick);
    map.on("mousedown", onMouseDown);
    map.on("move", onMapMove);
    map.on("load", fetchTile);

    return () => {
      map.off("click", onMapClick);
      map.off("mousedown", onMouseDown);
      map.off("move", onMapMove);
      map.off("load", fetchTile);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waypoints, canvas, map, onMapClick, onMouseDown, onMapMove, fetchTile]);

  // const mapboxStyles = [
  //   "outdoors-v11",
  //   "streets-v11",
  //   "satellite-streets-v11",
  //   "navigation-day-v1",
  //   "dark-v10",
  //   "light-v10",
  // ];
  const mapboxStyles = [
    { value: "outdoors-v11", label: "outdoors" },
    { value: "streets-v11", label: "streets" },
    { value: "satellite-streets-v11", label: "satellite" },
    { value: "navigation-day-v1", label: "navigation" },
    { value: "dark-v10", label: "dark" },
    { value: "light-v10", label: "light" },
  ];
  const [mapboxStyle, setMapboxStyle] = useState(mapboxStyles[0].value);

  const paces = Array(33)
    .fill(1)
    .map((_, i) => ({
      value: (Math.floor(i / 4) + 4) * 60 + ((i + 4) % 4) * 15,
      label: `${Math.floor(i / 4) + 4}:${
        ((i + 4) % 4) * 15 === 0 ? "00" : ((i + 4) % 4) * 15
      } min/mile`,
    }));
  const [secondsPerMile, setPace] = useState(paces[20].value);

  useEffect(() => {
    // mapboxgl.workerClass = MapboxWorker;
    const newMap = new mapboxgl.Map({
      container: mapContainer,
      style: `mapbox://styles/mapbox/${mapboxStyle}`,
      center: [lng, lat],
      zoom,
    });
    setMap(newMap);

    const canvas = newMap.getCanvasContainer();
    setCanvas(canvas);

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      marker: true,
    });

    document
      .getElementById("geocoder")
      ?.replaceChildren(geocoder.onAdd(newMap));

    clearWaypoints();
    goToMe(newMap);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapboxStyle]);

  useEffect(() => {
    if (isMobile) {
      startMobileAd();
    }
  }, []);

  const onDistanceClick = () => {
    setDistanceUnit(
      distanceUnit === DistanceUnit.KM ? DistanceUnit.MILES : DistanceUnit.KM
    );
  };

  const km = distance / 1000;
  const distanceFormatted = (
    distanceUnit === DistanceUnit.KM ? km : km * 0.621371
  ).toFixed(2);

  const timeToRunDistance = useMemo(
    () =>
      new Date(
        secondsPerMile *
          (distanceUnit === DistanceUnit.KM ? km : km * 0.621371) *
          1000
      )
        .toISOString()
        .substr(11, 8),
    [distanceUnit, km, secondsPerMile]
  );

  const [contactFormOpen, setContactFormOpen] = useState(false);
  const handleOpenContactForm = useCallback(() => setContactFormOpen(true), []);
  const handleCloseContactForm = useCallback(
    () => setContactFormOpen(false),
    []
  );

  return (
    <DragAndDrop handleDrop={onUpload}>
      <div
        className="mapContainer"
        // tabIndex={1}
        // style={{ zIndex: -1 }}
        style={{ height: "100vh" }}
        ref={(el) => {
          if (el) {
            mapContainer = el;
          }
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div id="mobile-ad" style={{ height: "fit-content" }} />
          {/* <div
        id="container-034a6c66c5c8d55b07371df01b562446"
        style={{ height: "fit-content" }}
      />
      <div
        id="container-524c12712d2c9b7367f25b446bba2ba5"
        style={{ height: "fit-content" }}
      /> */}
          {/* <div
        id="atContainer-524c12712d2c9b7367f25b446bba2ba5"
        style={{ height: "fit-content" }}
      /> */}
          {/* <div
        id="atContainer-524c12712d2c9b7367f25b446bba2ba5"
        style={{
          zIndex: 99,
          position: "absolute",
          bottom: 0,
          left: window.innerWidth / 2 - 728 / 2,
        }}
      /> */}

          <ContactForm
            open={contactFormOpen}
            onClose={handleCloseContactForm}
          />
          <Box
            sx={{
              position: "absolute",
              textAlign: "center",
              left: 0,
              paddingTop: 1,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 1,
              pointerEvents: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                zIndex: 1,
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <Button sx={styles.sidebarElement} onClick={onDistanceClick}>
                  <ArrowForwardIos />
                  <Typography variant="body1">
                    {distanceFormatted} {distanceUnit}
                  </Typography>
                </Button>
                <Box sx={styles.sidebarElement}>
                  <Landscape />
                  <Typography variant="body1">{elevation} meters</Typography>
                </Box>
                <IconButton
                  sx={styles.sidebarElement}
                  onClick={clearWaypoints}
                  disabled={!waypoints.length}
                >
                  <Clear />
                </IconButton>
                <IconButton
                  sx={styles.sidebarElement}
                  onClick={undoWaypoint}
                  disabled={!waypoints.length}
                >
                  <UndoIcon />
                </IconButton>
                <IconButton
                  sx={styles.sidebarElement}
                  onClick={() => map && goToMe(map)}
                >
                  <MyLocationIcon />
                </IconButton>
                <IconButton
                  sx={styles.sidebarElement}
                  onClick={() => setMenuDropdownOpen((o) => !o)}
                >
                  {menuDropdownOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                </IconButton>
                {!menuDropdownOpen ? null : (
                  <>
                    <Button
                      sx={styles.sidebarElement}
                      variant="contained"
                      color="primary"
                      onClick={generateGPX}
                    >
                      Export GPX
                    </Button>
                    <Button
                      sx={styles.sidebarElement}
                      variant="contained"
                      color="primary"
                      onClick={toggleHeatmap}
                    >
                      {heatmapEnabled ? "Hide" : "Show"} Heatmap
                    </Button>
                    {/* <Login sx={styles.sidebarElement} /> */}
                    <Select
                      value={mapboxStyle}
                      size="small"
                      onChange={(e) => setMapboxStyle(e.target.value)}
                      sx={styles.sidebarElement}
                    >
                      {mapboxStyles.map((s) => (
                        <MenuItem value={s.value} key={s.value}>
                          {s.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <Box sx={styles.sidebarElement} alignItems="center">
                      <Button
                        sx={{
                          ...styles.sidebarElement,
                          padding: 0,
                          margin: 0,
                        }}
                        variant="contained"
                        color="primary"
                        onClick={generateRandomRoute}
                      >
                        Random route
                      </Button>
                      <TextField
                        variant="standard"
                        size="small"
                        type="number"
                        inputProps={{
                          style: {
                            padding: 0,
                            border: "none",
                            textAlign: "center",
                          },
                          min: 1,
                          max: 100,
                        }}
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            padding: 0,
                            width: 80,
                            border: "none",
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography variant="body1">
                                {distanceUnit}
                              </Typography>
                            </InputAdornment>
                          ),
                        }}
                        value={randomRouteLength}
                        onChange={(e) => setRandomRouteLength(+e.target.value)}
                      />
                    </Box>
                    <Box sx={styles.sidebarElement} alignItems="center">
                      <Select
                        value={secondsPerMile}
                        size="small"
                        onChange={(e) => setPace(+e.target.value)}
                      >
                        {paces.map((s) => (
                          <MenuItem value={s.value} key={s.value}>
                            {s.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography variant="body1">
                        Time: {timeToRunDistance}
                      </Typography>
                    </Box>
                    <DropdownMenu
                      sx={styles.sidebarElement}
                      handleOpenContactForm={handleOpenContactForm}
                    />
                  </>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                paddingLeft: 1,
                marginRight: 2,
                width: 240,
              }}
            >
              <div id="geocoder" />
            </Box>
          </Box>
          <ElevationChart points={elevations} />
        </div>
      </div>
    </DragAndDrop>
  );
}

export default MapBoxLayer;
