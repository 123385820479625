export const START = "#2A9D8F";
export const START_HOVER = "#2A9D8F";
export const END = "#E76F51";
export const END_HOVER = "#E76F51";
export const ROUTE_POINT = "#FFFFFF";
export const ROUTE_POINT_WIDTH = 3;
export const ROUTE_LINE = "#2A9D8F";
export const ROUTE_LINE_WIDTH = 3;
export const WAYPOINT_OUTLINE = "#000000";
export const WAYPOINT_OUTLINE_WIDTH = 1;
