export const startAd = () => {
  setTimeout(() => {
    const div = document.getElementById(
      "atContainer-524c12712d2c9b7367f25b446bba2ba5"
    );

    const s1 = document.createElement("script");
    s1.type = "text/javascript";
    s1.innerHTML = `atOptions = {  
          'key': '524c12712d2c9b7367f25b446bba2ba5',
          'format': 'js',
          'async': true,
          'container': 'atContainer-524c12712d2c9b7367f25b446bba2ba5',
          'params' : {}
   };`;

    const s2 = document.createElement("script");
    s2.type = "text/javascript";
    s2.src = `http${
      window.location.protocol === "https:" ? "s" : ""
    }://laserdrivepreview.com/524c12712d2c9b7367f25b446bba2ba5/invoke.js`; // 728 × 90 px
    // }://laserdrivepreview.com/ce/d6/3b/ced63b6deebb583931dd617b447f6014.js`; // social bar
    while (div?.firstChild) {
      div.removeChild(div.firstChild);
    }
    div?.appendChild(s1);
    div?.appendChild(s2);
  }, 1000 * 1);
};

export const startMobileAd = () => {
  setTimeout(() => {
    const div = document.getElementById("mobile-ad");

    const s1 = document.createElement("script");
    s1.type = "text/javascript";
    s1.innerHTML = `atOptions = {  
          'key': '524c12712d2c9b7367f25b446bba2ba5',
          'format': 'js',
          'async': true,
          'container': 'atContainer-524c12712d2c9b7367f25b446bba2ba5',
          'params' : {}
   };`;

    const s2 = document.createElement("script");
    s2.type = "text/javascript";
    s2.src = `http${
      window.location.protocol === "https:" ? "s" : ""
    }://obeyroman.com/ce/d6/3b/ced63b6deebb583931dd617b447f6014.js`; // 728 × 90 px
    // }://laserdrivepreview.com/ce/d6/3b/ced63b6deebb583931dd617b447f6014.js`; // social bar
    // while (div?.firstChild) {
    //   div.removeChild(div.firstChild);
    // }
    // div?.appendChild(s1);
    div?.appendChild(s2);
  }, 1000 * 60);
};
