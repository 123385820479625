import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import React, { useState } from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import { API } from "./MapBoxLayer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh",
  overflowY: "scroll",
};

export const ContactForm = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [status, setStatus] = useState("Submit");
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setStatus("Sending...");
    const { name, email, message } = (e.target as HTMLFormElement)
      .elements as unknown as {
      name: { value: string };
      email: { value: string };
      message: { value: string };
    };
    const details = {
      name: name.value,
      email: email.value,
      message: message.value,
    };
    const response = await fetch(`${API}/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    const result = await response.json();
    console.log("response", response, result);
    if (response.ok && result.status !== "ERROR") {
      setStatus("Submit");
      onClose();
    } else {
      setStatus("Failed");
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Contact Form
        </Typography>

        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 2, width: "25ch" },
            paddingTop: 4,
          }}
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div>
            <TextField required id="name" label="Name" type="text" />
          </div>
          <div>
            <TextField required id="email" label="Email" type="email" />
          </div>
          <div>
            <TextField required id="message" label="Message" type="text" />
          </div>
          <div>
            <Button
              type="submit"
              sx={{
                marginTop: 4,
              }}
            >
              {status}
            </Button>
          </div>
        </Box>
      </Box>
    </Modal>
  );
};
