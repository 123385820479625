import MenuIcon from "@mui/icons-material/Menu";
import InfoIcon from "@mui/icons-material/Info";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import React, { MouseEvent, useCallback, useEffect } from "react";
import { policy, policyTitle } from "./policy";
import {
  Menu,
  MenuItem,
  Modal,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh",
  overflowY: "scroll",
};

export const DropdownMenu = ({
  sx,
  handleOpenContactForm,
}: {
  sx: object;
  handleOpenContactForm: () => void;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const dropdownOpen = Boolean(anchorEl);

  const handleClick = (
    event: MouseEvent<HTMLLIElement | HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpenPolicy = () => setOpen(true);
  const handleClosePolicy = () => setOpen(false);

  return (
    <div>
      <IconButton sx={sx} onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={dropdownOpen} onClose={handleClose}>
        <MenuItem>
          <ListItemIcon>
            <InfoIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText onClick={handleOpenPolicy}>Privacy Policy</ListItemText>
          <Modal open={open} onClose={handleClosePolicy}>
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {policyTitle}
              </Typography>
              <Typography id="modal-modal-description">{policy}</Typography>
            </Box>
          </Modal>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <InfoIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText onClick={handleOpenContactForm}>
            Contact Form
          </ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};
