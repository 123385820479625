import React from "react";

export const policyTitle = "Privacy & Cookie Policy";
export const policy2 = `
Router Mapper Map (routemapper.xyz, the "website") collects some personal data and stores identifiers on your device (like cookies) to provide the service, monitor performance and usage of the website, and for displaying advertisements. Please read below for more details and refer to the Terms of Service, which this Privacy & Cookie Policy is a part of.


Data We Collect

Website Logs and Cookies
Always Active
Whenever you use the website, we use Google Analytics and a modified version of Matomo we host in Digital Ocean to collect information about your visit including browser and device type, operating system, unique device identifiers stored in cookies, and details of how you use the website such as routes created, and timestamps of requests.

We analyze this information to monitor usage, fix bugs and performance issues, and to maintain and improve the website. We have legitimate interest in these activities, and this data collection is necessary to provide the service.

To the best of our ability none of the information we store in these logs is personally-identifying, for example we anonymized your IP address before storing it.

You can opt-out of Google Analytics tracking by installing Google's Opt-out browser extension, but the logs we collect using Matomo are always enabled.


Advertising Cookies

We use Google Adsense to display advertisements. Through Adsense, third party vendors and networks including Google use cookies to serve ads based on the content of this site and your visits to this and other sites.

Users in the European Economic Area (EEA) will only receive non-personalized advertisements. These ads still use cookies, but not for personalization. Users in other regions may opt-out of personalized advertising by visiting Google's Ad Settings. You may also opt-out of some third-party vendors and ad networks' use of cookies and personalized advertising at www.aboutads.info.

You may disable ads altogether using the toggle button in the header of this section. We store this preference in a cookie, so you must enable cookies for this to take effect.


Data You Provide

Location Data
Optional
When you click the button to locate yourself on the map, you provide your current location to the website. We only use that information to display your location on the map. We do not log or store this information or use it for any other purpose.

To disable this functionality, you can block geolocation permission for this website in your browser.

We will prompt you before requesting location data.


Google Drive
Optional
You may choose to grant Router Mapper Map access to your Google Account so that you can open routes created using an earlier version of Router Mapper Map from your Google Drive. We use that permission only to open routes created with this application. We do not use or access any personal data associated with your Google account.

When you use this functionality, Google sets cookies on your device. See Google's Privacy Policy for details of how they use and process your information.

You may revoke access to the Google Drive app at any time at https://myaccount.google.com/permissions.


Feedback
Optional
You may provide certain personal information such as email address when you fill out the feedback form or send us an email. We use this information only to communicate with you about the feedback you give.

When you send a message to any @routemapper.xyz email address, it goes through Mailgun which stores and processes your email according to Mailgun's Privacy Policy.

Please email privacy@routemapper.xyz if you want your feedback removed from our systems.


Routes You Share
Optional
When you share or embed a route, the URL that you share contains the route and can be publicly viewed by anyone with the link. Third party services that the website uses also have access to the route since they may log the URL of the page that they are loaded on.

You may choose to shorten the URL for a route that you created. This stores your route and returns a short URL that redirects to your route when someone visits it. By default, these short routes are stored forever but you can contact privacy@routemapper.xyz to remove any previously-shortened URL.

Routes shortened before May 2020 used bit.ly which stores those shortened routes forever and collects statistics about people who click-through to your route. See bit.ly's Privacy Policy for full details.


How We Protect Your Data
We use industry standard techniques to protect against unauthorized access of data about you that we transfer and store, including personal information and routes created. To the best of our ability all resources requested when you use the site use https and we encrypt all logs when we store them in Amazon S3.


How We Share Your Data
We use hosting providers including Cloudflare, Amazon Web Services, and Digital Ocean to bring the site to you. All traffic to the website including personal information may go through these hosting providers. See their privacy policies and data processing agreements for details about how they process your information.

We use Google Analytics to collect and analyze usage data. For more information see How Google uses data when you use our partners' sites or apps. We have configured Google Analytics to delete usage data after 26 months.

We use Stadia Maps to host map data and Esri to host map data and provide search results. See Stadia Maps' Privacy Policy or Esri's Privacy Policy for details on how they use and process your information.

Beyond to these known parties, we may also need to:

Share your information with people or entities that we hire or contract with, but only the minimum necessary information so they can accomplish their task
Share your information with law enforcement to meet any applicable law, regulation, legal process, or enforceable government request
Share usage statistics with third parties, but only in an aggregated and anonymized form
Buy or sell assets which require transferring your information, but only so it can be used in accordance with policy
By agreeing to this policy, you agree to our sharing of your data with these parties.


Your Rights
If you wish to request, change, or delete any of your information or if you believe we hold any information about you in violation of this policy, please email us at privacy@routemapper.xyz.


Data Transfer
Router Mapper Map is based in the United States. Personal information that we collect may be transferred or stored by services we use inside or outside the European Economic Area or Switzerland, including in the United States. By agreeing to this policy, you agree to such transfers.


Changes to this Policy
We may change this Privacy Policy. Use of information we collect is subject to the Privacy Policy in effect at the time such information is used. If we make major changes in the way we collect or use information, we will make a reasonable effort to notify you such as a pop-up or notification when you use the website. Continuing to use the website after we post the change indicates you have read and agree to the new policy.


Contact Us
For any questions about this Privacy Policy, please email us at privacy@routemapper.xyz.

Last modified: April 29, 2020.
`;
export const policy = (
  <span>
    Router Mapper Map (routemapper.xyz, the "website") collects some personal
    data and stores identifiers on your device (like cookies) to provide the
    service, monitor performance and usage of the website, and for displaying
    advertisements. Please read below for more details and refer to the Terms of
    Service, which this Privacy & Cookie Policy is a part of.
    <h2>Data We Collect</h2>
    <h2>Website Logs and Cookies</h2>
    Whenever you use the website, we use Google Analytics and a modified version
    of Matomo we host in Digital Ocean to collect information about your visit
    including browser and device type, operating system, unique device
    identifiers stored in cookies, and details of how you use the website such
    as routes created, and timestamps of requests. We analyze this information
    to monitor usage, fix bugs and performance issues, and to maintain and
    improve the website. We have legitimate interest in these activities, and
    this data collection is necessary to provide the service. To the best of our
    ability none of the information we store in these logs is
    personally-identifying, for example we anonymized your IP address before
    storing it. You can opt-out of Google Analytics tracking by installing
    Google's Opt-out browser extension, but the logs we collect using Matomo are
    always enabled.
    <h2>Advertising Cookies</h2>
    We use Google Adsense to display advertisements. Through Adsense, third
    party vendors and networks including Google use cookies to serve ads based
    on the content of this site and your visits to this and other sites. Users
    in the European Economic Area (EEA) will only receive non-personalized
    advertisements. These ads still use cookies, but not for personalization.
    Users in other regions may opt-out of personalized advertising by visiting
    Google's Ad Settings. You may also opt-out of some third-party vendors and
    ad networks' use of cookies and personalized advertising at
    www.aboutads.info. You may disable ads altogether using the toggle button in
    the header of this section. We store this preference in a cookie, so you
    must enable cookies for this to take effect.
    <h2>Data You Provide</h2>
    When you click the button to locate yourself on the map, you provide your
    current location to the website. We only use that information to display
    your location on the map. We do not log or store this information or use it
    for any other purpose. To disable this functionality, you can block
    geolocation permission for this website in your browser. We will prompt you
    before requesting location data.
    <h2>Feedback</h2>
    You may provide certain personal information such as email address when you
    fill out the feedback form or send us an email. We use this information only
    to communicate with you about the feedback you give. When you send a message
    to any @routemapper.xyz email address, it goes through Mailgun which stores
    and processes your email according to Mailgun's Privacy Policy. Please email
    privacy@routemapper.xyz if you want your feedback removed from our systems.
    <h2>Routes You Share</h2>
    When you share or embed a route, the URL that you share contains the route
    and can be publicly viewed by anyone with the link. Third party services
    that the website uses also have access to the route since they may log the
    URL of the page that they are loaded on. You may choose to shorten the URL
    for a route that you created. This stores your route and returns a short URL
    that redirects to your route when someone visits it. By default, these short
    routes are stored forever but you can contact privacy@routemapper.xyz to
    remove any previously-shortened URL. Routes shortened before May 2020 used
    bit.ly which stores those shortened routes forever and collects statistics
    about people who click-through to your route. See bit.ly's Privacy Policy
    for full details.
    <h2>How We Protect Your Data</h2>
    We use industry standard techniques to protect against unauthorized access
    of data about you that we transfer and store, including personal information
    and routes created. To the best of our ability all resources requested when
    you use the site use https and we encrypt all logs when we store them in
    Amazon S3.
    <h2>How We Share Your Data</h2>
    We use hosting providers including Cloudflare, Amazon Web Services, and
    Digital Ocean to bring the site to you. All traffic to the website including
    personal information may go through these hosting providers. See their
    privacy policies and data processing agreements for details about how they
    process your information. We use Google Analytics to collect and analyze
    usage data. For more information see How Google uses data when you use our
    partners' sites or apps. We have configured Google Analytics to delete usage
    data after 26 months. We use Stadia Maps to host map data and Esri to host
    map data and provide search results. See Stadia Maps' Privacy Policy or
    Esri's Privacy Policy for details on how they use and process your
    information. Beyond to these known parties, we may also need to: Share your
    information with people or entities that we hire or contract with, but only
    the minimum necessary information so they can accomplish their task Share
    your information with law enforcement to meet any applicable law,
    regulation, legal process, or enforceable government request Share usage
    statistics with third parties, but only in an aggregated and anonymized form
    Buy or sell assets which require transferring your information, but only so
    it can be used in accordance with policy By agreeing to this policy, you
    agree to our sharing of your data with these parties.
    <h2>Your Rights</h2>
    If you wish to request, change, or delete any of your information or if you
    believe we hold any information about you in violation of this policy,
    please email us at privacy@routemapper.xyz.
    <h2>Data Transfer</h2>
    Router Mapper Map is based in the United States. Personal information that
    we collect may be transferred or stored by services we use inside or outside
    the European Economic Area or Switzerland, including in the United States.
    By agreeing to this policy, you agree to such transfers.
    <h2>Changes to this Policy</h2>
    We may change this Privacy Policy. Use of information we collect is subject
    to the Privacy Policy in effect at the time such information is used. If we
    make major changes in the way we collect or use information, we will make a
    reasonable effort to notify you such as a pop-up or notification when you
    use the website. Continuing to use the website after we post the change
    indicates you have read and agree to the new policy.
    <h2>Contact Us</h2>
    For any questions about this Privacy Policy, please email us at
    privacy@routemapper.xyz. Last modified: April 29, 2020.
  </span>
);
