import { useEffect, useRef, useState } from "react";

export function usePrevious<T>(value: T): T {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current as T;
}

export const usePersistedState = <T>(key: string, defaultValue: T) => {
  const [state, setState] = useState(
    () => JSON.parse(localStorage.getItem(key) ?? "null") ?? defaultValue
  );
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
};
