import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import { Dispatch } from "react";
import "./App.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

const lightTheme = responsiveFontSizes(
  createTheme({
    palette: {
      mode: "light",
    },
  })
);

const darkTheme = responsiveFontSizes(
  createTheme({
    palette: {
      mode: "dark",
    },
    spacing: 4,
    shape: { borderRadius: 1 },
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            padding: 0,
            paddingLeft: 4,
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          input: {
            padding: 0,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
    },
  })
);

type State = {
  isLoggedIn: boolean;
  userData: unknown;
};

type Action = { type: "logout" } | { type: "login"; payload: unknown };

type ContextType = {
  isLoggedIn: boolean;
  userData: unknown;
  dispatch: Dispatch<Action>;
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "login":
      return { ...state, isLoggedIn: true, userData: action.payload };
    case "logout":
    default:
      return { ...state, isLoggedIn: false };
  }
}

const initialState: State = {
  isLoggedIn: false,
  userData: null,
};

const initialContext: ContextType = {
  isLoggedIn: false,
  userData: null,
  dispatch: () => null,
};

export const MyContext = React.createContext(initialContext);
import {
  Outlet,
  RouterProvider,
  Router,
  Route,
  RootRoute,
} from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import MapBoxLayer from "./Components/MapBoxLayer";
import { EditGpx } from "./Components/EditGpx";

const rootRoute = new RootRoute({
  component: () => (
    <div style={{ height: "100vh" }}>
      <Outlet />
      {/* <TanStackRouterDevtools /> */}
    </div>
  ),
});

const indexRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "*",
  component: MapBoxLayer,
});

const index2Route = new Route({
  getParentRoute: () => rootRoute,
  path: "/",
  component: MapBoxLayer,
});

const editRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/edit",
  component: EditGpx,
});

const routeTree = rootRoute.addChildren([indexRoute, index2Route, editRoute]);

const router = new Router({ routeTree });

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

export default function MyApp() {
  const [isThemeLight] = React.useState(false);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const context = React.useMemo(() => ({ ...state, dispatch }), [state]);
  return (
    <MyContext.Provider value={context}>
      <ThemeProvider theme={isThemeLight ? lightTheme : darkTheme}>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_API_TOKEN ?? ""}
        >
          <CssBaseline />
          <RouterProvider router={router} />
        </GoogleOAuthProvider>
      </ThemeProvider>
    </MyContext.Provider>
  );
}
