import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import MyLocationIcon from "@mui/icons-material/MyLocation";

import {
  Box,
  Button,
  IconButton,
  Input,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  AnyRoute,
  Link,
  useNavigate,
  useRouterState,
} from "@tanstack/react-router";
import gpxParser, {
  Point as GpxParserPoint,
  Track as GpxParserTrack,
} from "gpxparser";
import mapboxgl, {
  EventData,
  GeoJSONSource,
  Map,
  MapEventType,
  MapMouseEvent,
} from "mapbox-gl";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { API, DistanceUnit, MouseButton, Point, styles } from "./MapBoxLayer";
import {
  ArrowForwardIos,
  Landscape,
  Clear,
  ArrowDropUp,
  ArrowDropDown,
  ArrowBack,
} from "@mui/icons-material";
import saveAs from "file-saver";
import { GarminBuilder, buildGPX } from "gpx-builder";
import { usePersistedState } from "../utils/utils";
import { startMobileAd } from "./Adsterra";
import { ContactForm } from "./ContactForm";
import DragAndDrop from "./DragAndDrop";
import { DropdownMenu } from "./DropdownMenu";
import { ElevationPoint, ElevationChart } from "./ElevationChart";
import { lat2tile, lon2tile, tile2lat, tile2long } from "../utils/helpers";
import debounce from "debounce";
import {
  Route,
  Segment,
  Track,
} from "gpx-builder/dist/builder/BaseBuilder/models";
import {
  WAYPOINT_OUTLINE_WIDTH,
  WAYPOINT_OUTLINE,
  ROUTE_POINT_WIDTH,
  ROUTE_POINT,
  ROUTE_LINE,
  ROUTE_LINE_WIDTH,
} from "../utils/colours";

let layers: { overlayId: string; lng: number; lat: number }[] = [];

export const fetchImage = debounce(
  async (
    _lng: number,
    _lat: number,
    _zoom: number,
    map: Map,
    heatmapEnabled: boolean
  ) => {
    let zoom = Math.round(_zoom);
    if (zoom > 15) {
      zoom = 15;
    }
    const _x = lon2tile(_lng, zoom);
    const _y = lat2tile(_lat, zoom);
    const tileRange = 2;
    for (let x = _x - tileRange; x < _x + tileRange; x++) {
      for (let y = _y - tileRange; y < _y + tileRange; y++) {
        const sourceId = `tile-${x}-${y}-${zoom}`;
        const overlayId = `overlay-${x}-${y}-${zoom}`;
        const source = map.getSource(sourceId) as mapboxgl.ImageSource;
        if (source) {
          // already exists
          // source.updateImage({
          //   url: objectURL,
          //   coordinates
          // });
          break;
        }
        fetch(`${API}/api?x=${x}&y=${y}&zoom=${zoom}`)
          .then((resp) => resp.blob())
          .then((blob) => {
            if (blob.type !== "image/png") {
              return;
            }
            const objectURL = URL.createObjectURL(blob);

            const lng = tile2long(x, zoom);
            const lat = tile2lat(y, zoom);
            const lengthX = tile2long(x + 1, zoom) - lng;
            const lengthY = tile2lat(y - 1, zoom) - lat;
            const coordinates = [
              [lng, lat],
              [lng + lengthX, lat],
              [lng + lengthX, lat - lengthY],
              [lng, lat - lengthY],
            ];
            map.addSource(sourceId, {
              type: "image",
              url: objectURL,
              coordinates,
            });
            layers.push({ overlayId, lng, lat });
            map.addLayer({
              id: overlayId,
              source: sourceId,
              type: "raster",
              paint: {
                "raster-opacity": 0.25,
              },
            });
            map.setLayoutProperty(
              overlayId,
              "visibility",
              heatmapEnabled ? "visible" : "none"
            );
          })
          .catch((e) => {
            console.log("error fetching zoom", zoom, e);
          });
      }
    }
  },
  1000
);

export const EditGpx = () => {
  const routerState = useRouterState<
    AnyRoute,
    {
      location: { state: { gpx?: gpxParser } };
    }
  >();

  const [heatmapEnabled, setHeatmapEnabled] = usePersistedState(
    "heatmapEnabled",
    true
  );
  const isMobile = useMemo(() => window.innerWidth <= 768, []);
  const [menuDropdownOpen, setMenuDropdownOpen] = React.useState(!isMobile);
  const [waypoints, setWaypoints] = React.useState<[number, number][]>([]);
  const [route, setRoute] = React.useState<GeoJSON.Position[]>([]);
  const [map, setMap] = React.useState<Map | null>(null);
  const [canvas, setCanvas] = React.useState<HTMLElement>();
  const [lng, setLng] = React.useState(-0.1339);
  const [lat, setLat] = React.useState(51.5273);
  const [zoom, setZoom] = React.useState(14);
  const [distance, setDistance] = React.useState(0);
  const [distanceUnit, setDistanceUnit] = usePersistedState(
    "distanceUnit",
    DistanceUnit.MILES
  );
  const [elevations, setElevations] = React.useState<ElevationPoint[]>([]);
  const [elevation, setElevation] = React.useState(0);
  const [timeToRunDistance, setTimeToRunDistance] = React.useState(0);
  const [waypointHover, setWaypointHover] = React.useState(false);
  const [track, setTrack] = React.useState<GpxParserTrack>();

  const mapContainer = useRef<HTMLElement>();

  useEffect(() => {
    const totalDistance = track?.distance.total ?? 0;
    setDistance(totalDistance);
    setElevation(Math.floor(track?.elevation.pos ?? 0));
    const points = track?.points;

    if (points?.length) {
      const elapsedSeconds =
        (points[points.length - 1].time.valueOf() - points[0].time.valueOf()) /
        1000;
      const totalSeconds = points.reduce((total, point, i, points) => {
        const diff =
          point.time.valueOf() / 1000 - points[i - 1]?.time.valueOf() / 1000;
        return i > 1 && diff < 5 ? total + diff : total;
      }, 0);
      console.log(
        "totalSeconds",
        track,
        points,
        elapsedSeconds,
        totalSeconds,
        totalDistance
      );
      setSecondsPerMile(totalSeconds / (totalDistance / 1000) / 0.621371);
      setTimeToRunDistance(totalSeconds / 60);
    }
  }, [track, track?.distance.total, track?.elevation.pos, track?.points]);

  useEffect(() => {
    const gpx = routerState.location.state.gpx;
    const track = gpx?.tracks[0];
    setTrack(gpx?.tracks[0]);
    // const totalDistance = track?.distance.total ?? 0;
    // setDistance(totalDistance);
    // setElevation(Math.floor(track?.elevation.pos ?? 0));

    // if (points?.length) {
    //   const totalSeconds =
    //     (points[points.length - 1].time.valueOf() - points[0].time.valueOf()) /
    //     1000;
    //   console.log("totalSeconds", totalSeconds, totalDistance);
    //   setSecondsPerMile(totalSeconds / (totalDistance / 1000) / 0.621371);
    //   setTimeToRunDistance(totalSeconds / 60);
    // }

    // console.log("a.location.state.gpx.", track);
  }, [routerState.location.state.gpx]);

  const addLayer = React.useCallback(
    (
      id: string,
      data:
        | GeoJSON.Feature<GeoJSON.Geometry>
        | GeoJSON.FeatureCollection<GeoJSON.Geometry>,
      radius: number,
      initialColour: string,
      hoverColour: string
    ) => {
      if (!map || !canvas?.style) {
        return;
      }
      map.addLayer({
        id,
        type: "circle",
        source: {
          type: "geojson",
          data,
        },
        paint: {
          "circle-radius": radius,
          "circle-color": initialColour,
          "circle-stroke-width": WAYPOINT_OUTLINE_WIDTH,
          "circle-stroke-color": WAYPOINT_OUTLINE,
        },
      });
      map.on("mouseenter", id, () => {
        setWaypointHover(true);
        map.setPaintProperty(id, "circle-color", hoverColour);
        canvas.style.cursor = "move";
      });
      map.on("mouseleave", id, () => {
        map.setPaintProperty(id, "circle-color", initialColour);
        if (canvas) {
          canvas.style.cursor = "";
        }
        setWaypointHover(false);
      });
    },
    [canvas, map]
  );

  useEffect(() => {
    if (map && map.loaded()) {
      if (track?.points?.length) {
        const newWaypoints = track.points.map(
          (p) => [p.lon, p.lat] as [number, number]
        );
        console.log("map", map, map.loaded());
        setWaypoints(newWaypoints);

        for (let i = 1; i < newWaypoints.length - 1; i++) {
          //   const id = `point${i}`;
          //   const point = newWaypoints[i];
          //   const data = {
          //     type: "Feature",
          //     properties: {},
          //     geometry: {
          //       type: "Point",
          //       coordinates: [point[0], point[1]],
          //     },
          //   } as GeoJSON.Feature<GeoJSON.Geometry>;
          //   if (map.getSource(id)) {
          //     (map.getSource(id) as GeoJSONSource).setData(data);
          //   } else {
          //     addLayer(id, data, ROUTE_POINT_WIDTH, ROUTE_POINT, ROUTE_POINT);
          //   }
        }
        const geojson = {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: newWaypoints,
          },
        } as GeoJSON.Feature<GeoJSON.Geometry>;
        if (map.getSource("route")) {
          (map.getSource("route") as GeoJSONSource).setData(geojson);
        } else {
          map.addLayer({
            id: "route",
            type: "line",
            source: {
              type: "geojson",
              data: geojson,
            },
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": ROUTE_LINE,
              "line-width": ROUTE_LINE_WIDTH,
              "line-opacity": 0.75,
            },
          });
        }
      }
    }

    // setRoute(points.map( p=> ({lat: p.x, lon: p.y})));
  }, [addLayer, map, track?.points]);

  const removeLayer = useCallback(
    (id: string) => {
      if (map?.getSource(id)) {
        map.removeLayer(id);
        map.removeSource(id);
      }
    },
    [map]
  );

  const goToMe = async (newMap: mapboxgl.Map) => {
    await navigator.geolocation.getCurrentPosition((position) => {
      newMap.setCenter({
        lon: position.coords.longitude,
        lat: position.coords.latitude,
      });
      newMap.zoomTo(14);
    });
  };

  const navigate = useNavigate({ from: "/" });

  const readFile = useCallback(
    (event: any) => {
      const xml = event.target.result;
      const gpx = new gpxParser();
      gpx.parse(xml);
      navigate({ to: "/edit", state: (s) => ({ ...s, gpx }) });
    },
    [navigate]
  );

  const onUpload = useCallback(
    (fileList: FileList) => {
      console.log("e", fileList.item(0));

      const reader = new FileReader();
      reader.addEventListener("load", readFile);
      reader.readAsText(fileList.item(0)!);
    },
    [readFile]
  );

  const generateGPX = () => {
    const prev = routerState.location.state.gpx;
    if (track && prev) {
      const gpx = new gpxParser();
      gpx.tracks = [track];
      gpx.metadata = prev.metadata;
      //   const csv = gpx.xmlSource;
      //   console.log("gpx", gpx, gpx.);
      // gpx.

      // const points = route.map(
      //   (waypoint: number[]) => new Point(waypoint[1], waypoint[0])
      // );
      const gpxData = new GarminBuilder();
      gpxData.setTracks([
        new Track([
          new Segment(
            track.points.map((p) => new Point(p.lat, p.lon, { time: p.time }))
          ),
        ]),
      ]);
      //   gpxData.setMetadata(prev.metadata);
      // gpxData.setSegmentPoints(points);
      const csv = buildGPX(gpxData.toObject());
      const blob = new Blob([csv], { type: "text/xml" });
      saveAs(blob, "route.gpx");
    }
  };

  const clearWaypoints = useCallback(() => {
    removeLayer("start");
    removeLayer("end");
    removeLayer("route");
    for (let i = 1; i < waypoints.length - 1; i++) {
      removeLayer(`point${i}`);
    }
    setWaypoints([]);
    setRoute([]);
    setDistance(0);
    setElevation(0);
    setElevations([]);
  }, [removeLayer, waypoints.length]);

  const onMove = React.useCallback(
    (pointIndex: number) => (e: MapEventType["mousemove"] & EventData) => {
      const coords = e.lngLat;
      if (canvas) {
        canvas.style.cursor = "grabbing";
      }
      const geojson = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Point",
              coordinates: [coords.lng, coords.lat],
            },
          },
        ],
      } as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
      // const distances = waypoints.map(
      //   (waypoint) =>
      //     Math.abs(waypoint[0] - e.lngLat.lng) +
      //     Math.abs(waypoint[1] - e.lngLat.lat)
      // );
      if (pointIndex > -1) {
        (
          map?.getSource(
            pointIndex === 0
              ? "start"
              : pointIndex === waypoints.length - 1
              ? "end"
              : `point${pointIndex}`
          ) as GeoJSONSource
        )?.setData(geojson);
      }
    },
    [canvas, map, waypoints.length]
  );

  const onMapClick = React.useCallback(
    async (e: MapEventType["click"] & EventData) => {
      if (waypointHover) {
        return;
      }
      //   addClosestMapPoint(e.lngLat);
    },
    [waypointHover]
  );

  const onMouseUp = React.useCallback(
    (pointIndex: number) => (e: MapEventType["mouseup"] & EventData) => {
      const newWaypoints = [...waypoints];
      const { lat, lng } = e.lngLat;
      newWaypoints[pointIndex] = [lng, lat];
      setWaypoints(newWaypoints);
      if (canvas) {
        canvas.style.cursor = "";
      }
    },
    [canvas, waypoints]
  );

  const fetchTile = useCallback(async () => {
    // console.log('a')
    // map.on("load", () => {
    //   // $('.mapboxgl-canvas').focus();
    // });
    // console.log(";oadn", document.getElementsByClassName("mapboxgl-canvas"));
    // (
    //   document.getElementsByClassName("mapboxgl-canvas")[0] as HTMLElement
    // ).focus();

    if (!map) {
      return;
    }
    await fetchImage(lng, lat, zoom, map, heatmapEnabled);
  }, [map, lng, lat, zoom, heatmapEnabled]);

  useEffect(() => {
    fetchTile();
  }, [lng, lat, fetchTile]);

  useEffect(() => {
    const bounds = map?.getBounds();
    if (bounds) {
      const tilesInView = layers.filter(
        ({ overlayId, lng: layerLng, lat: layerLat }) => {
          const isInView =
            layerLng < bounds.getNorthEast().lng &&
            layerLng > bounds.getSouthWest().lng &&
            layerLat < bounds.getNorthEast().lat &&
            layerLat > bounds.getSouthWest().lat;
          if (map?.getLayer(overlayId)) {
            map?.removeLayer(overlayId);
          }
          return isInView;
        }
      );
      layers = tilesInView;
    }
  }, [map, zoom]);

  useEffect(() => {
    if (!map) {
      return;
    }
    layers.forEach(({ overlayId }) => {
      map.setLayoutProperty(
        overlayId,
        "visibility",
        heatmapEnabled ? "visible" : "none"
      );
    });
  }, [heatmapEnabled, map]);

  const onMapMove = useCallback((e: MapMouseEvent) => {
    const newLng = +e.target.getCenter().lng;
    const newLat = +e.target.getCenter().lat;
    const newZoom = Math.round(+e.target.getZoom());
    setLng(newLng);
    setLat(newLat);
    setZoom(newZoom);
  }, []);

  const onMouseDown = React.useCallback(
    (e: MapMouseEvent) => {
      const {
        lngLat: { lat, lng },
        originalEvent: { button },
      } = e;
      const distances = waypoints.map(
        (waypoint) => Math.abs(waypoint[0] - lng) + Math.abs(waypoint[1] - lat)
      );
      const closest = Math.min(...distances);
      const pointIndex = distances.findIndex((d) => d === closest);
      if (pointIndex > -1 && closest < 0.001) {
        e.preventDefault();
        if (button === MouseButton.LEFT) {
          if (canvas) {
            canvas.style.cursor = "grab";
          }
          const onMoveListener = onMove(pointIndex);
          map?.on("mousemove", onMoveListener);
          map?.once("mouseup", (e) => {
            onMouseUp(pointIndex)(e);
            map?.off("mousemove", onMoveListener);
          });
        } else if (button === MouseButton.RIGHT) {
          setWaypoints(waypoints.filter((_, i) => i !== pointIndex));
        }
      }
    },
    [canvas, map, onMouseUp, onMove, waypoints]
  );

  React.useEffect(() => {
    if (!map) return;
    map.on("click", onMapClick);
    map.on("mousedown", onMouseDown);
    map.on("move", onMapMove);
    map.on("load", fetchTile);

    return () => {
      map.off("click", onMapClick);
      map.off("mousedown", onMouseDown);
      map.off("move", onMapMove);
      map.off("load", fetchTile);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waypoints, canvas, map, onMapClick, onMouseDown, onMapMove, fetchTile]);

  // const mapboxStyles = [
  //   "outdoors-v11",
  //   "streets-v11",
  //   "satellite-streets-v11",
  //   "navigation-day-v1",
  //   "dark-v10",
  //   "light-v10",
  // ];
  const mapboxStyles = [
    { value: "outdoors-v11", label: "outdoors" },
    { value: "streets-v11", label: "streets" },
    { value: "satellite-streets-v11", label: "satellite" },
    { value: "navigation-day-v1", label: "navigation" },
    { value: "dark-v10", label: "dark" },
    { value: "light-v10", label: "light" },
  ];
  const [mapboxStyle, setMapboxStyle] = useState(mapboxStyles[0].value);

  const paces = Array(33)
    .fill(1)
    .map((_, i) => ({
      value: (Math.floor(i / 4) + 4) * 60 + ((i + 4) % 4) * 15,
      label: `${Math.floor(i / 4) + 4}:${
        ((i + 4) % 4) * 15 === 0 ? "00" : ((i + 4) % 4) * 15
      } min/mile`,
    }));
  const [secondsPerMile, setSecondsPerMile] = useState(paces[20].value);
  const secondsPerMileTimeValue = useMemo(() => {
    const minutes = Math.floor(secondsPerMile / 60);
    const seconds = Math.floor(secondsPerMile % 60);
    console.log(
      "value",
      `${minutes < 10 ? "0" + minutes : minutes}:${seconds}`
    );
    return `${minutes < 10 ? "0" + minutes : minutes}:${
      seconds < 10 ? "0" + seconds : seconds
    }`;
  }, [secondsPerMile]);

  useEffect(() => {
    const newMap = new mapboxgl.Map({
      container: mapContainer.current ?? "mapContainer",
      style: `mapbox://styles/mapbox/${mapboxStyle}`,
      center: [lng, lat],
      zoom,
    });
    setMap(newMap);

    const canvas = newMap.getCanvasContainer();
    setCanvas(canvas);

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      marker: true,
    });

    document
      .getElementById("geocoder")
      ?.replaceChildren(geocoder.onAdd(newMap));

    clearWaypoints();
    goToMe(newMap);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapboxStyle]);

  useEffect(() => {
    if (isMobile) {
      startMobileAd();
    }
  }, [isMobile]);

  const onDistanceClick = () => {
    setDistanceUnit(
      distanceUnit === DistanceUnit.KM ? DistanceUnit.MILES : DistanceUnit.KM
    );
  };

  const km = distance / 1000;
  const distanceFormatted = useMemo(
    () => (distanceUnit === DistanceUnit.KM ? km : km * 0.621371).toFixed(2),
    [distanceUnit, km]
  );

  const [contactFormOpen, setContactFormOpen] = useState(false);
  const handleOpenContactForm = useCallback(() => setContactFormOpen(true), []);
  const handleCloseContactForm = useCallback(
    () => setContactFormOpen(false),
    []
  );

  return (
    <DragAndDrop handleDrop={onUpload}>
      <div
        className="mapContainer"
        style={{ height: "100vh" }}
        ref={(el) => {
          if (el) {
            mapContainer.current = el;
          }
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div id="mobile-ad" style={{ height: "fit-content" }} />
          <ContactForm
            open={contactFormOpen}
            onClose={handleCloseContactForm}
          />
          <Box
            sx={{
              position: "absolute",
              textAlign: "center",
              left: 0,
              paddingTop: 1,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 1,
              pointerEvents: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                zIndex: 1,
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <Link to="/">
                  <Box sx={styles.sidebarElement}>
                    <ArrowBack />
                    <Typography variant="body1">
                      Go back to route creation
                    </Typography>
                  </Box>
                </Link>
                <Button sx={styles.sidebarElement} onClick={onDistanceClick}>
                  <ArrowForwardIos />
                  <Typography variant="body1">
                    {distanceFormatted} {distanceUnit}
                  </Typography>
                </Button>
                <Box sx={styles.sidebarElement}>
                  <Landscape />
                  <Typography variant="body1">{elevation} meters</Typography>
                </Box>
                <IconButton
                  sx={styles.sidebarElement}
                  onClick={clearWaypoints}
                  disabled={!waypoints.length}
                >
                  <Clear />
                </IconButton>
                <IconButton
                  sx={styles.sidebarElement}
                  onClick={() => map && goToMe(map)}
                >
                  <MyLocationIcon />
                </IconButton>
                <IconButton
                  sx={styles.sidebarElement}
                  onClick={() => setMenuDropdownOpen((o) => !o)}
                >
                  {menuDropdownOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                </IconButton>
                {!menuDropdownOpen ? null : (
                  <>
                    <Button
                      sx={styles.sidebarElement}
                      variant="contained"
                      color="primary"
                      onClick={generateGPX}
                    >
                      Export GPX
                    </Button>
                    <Select
                      value={mapboxStyle}
                      size="small"
                      onChange={(e) => setMapboxStyle(e.target.value)}
                      sx={styles.sidebarElement}
                    >
                      {mapboxStyles.map((s) => (
                        <MenuItem value={s.value} key={s.value}>
                          {s.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <Box sx={styles.sidebarElement} alignItems="center">
                      {/* <Select
                        value={secondsPerMile}
                        size="small"
                        onChange={(e) => setPace(+e.target.value)}
                      >
                        {paces.map((s) => (
                          <MenuItem value={s.value} key={s.value}>
                            {s.label}
                          </MenuItem>
                        ))}
                      </Select> */}
                      <Input
                        type="time"
                        value={secondsPerMileTimeValue}
                        // inputProps={{min}}
                        inputProps={{ inputProps: { min: 60, max: 600 } }}
                        onChange={(e) => {
                          console.log("e", e.target.value);
                          const [m, s] = e.target.value.split(":");
                          //   console.log("h", (+m ?? 0) * 60 + (+s ?? 0));
                          let newSecondsPerMile = (+m ?? 0) * 60 + (+s ?? 0);

                          if (newSecondsPerMile < 60) {
                            newSecondsPerMile = 60;
                          } else if (newSecondsPerMile > 60 * 20) {
                            newSecondsPerMile = 60 * 20;
                          }
                          if (track) {
                            const newPoints = track.points.map((p) => ({
                              ...p,
                              time: new Date(
                                (p.time.valueOf() * newSecondsPerMile) /
                                  secondsPerMile
                              ),
                            }));
                            setTrack({
                              ...track,
                              points: newPoints,
                            });
                          }

                          setSecondsPerMile(newSecondsPerMile);
                        }}
                      />
                      <Typography variant="body1">
                        Time: {Math.round(timeToRunDistance)}
                      </Typography>
                    </Box>
                    <DropdownMenu
                      sx={styles.sidebarElement}
                      handleOpenContactForm={handleOpenContactForm}
                    />
                  </>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                paddingLeft: 1,
                marginRight: 2,
                width: 240,
              }}
            >
              <div id="geocoder" />
            </Box>
          </Box>
          <ElevationChart points={elevations} />
        </div>
      </div>
    </DragAndDrop>
  );
};
